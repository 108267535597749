import React from 'react';
import { ImageCard } from '@components/molecules/ImageCard/ImageCard';

export default function El(Element) {
  return (
    <div className="relative size-full lg:px-32">
      {Element?.image?.path && (
        <ImageCard
          imageSrc={Element.image.path}
          imageAlt={Element.headline}
          width={Element.image.width}
          height={Element.image.height}
          description={Element.caption || Element.image.caption}
          copyright={Element.image.copyright}
          sizes="(min-width: 1020px) 820px, 100vw"
          classProps={{
            ImageCard: 'rounded-lg',
            Image: 'rounded-t-lg object-cover',
            ImageText: 'text-sm',
            root: 'rounded-lg',
          }}
        />
      )}
    </div>
  );
}
