import React from 'react';
import { useCollapse } from '@collapsed/react';
import { MenuItem } from '@vgn-medien-holding/vgn-react-components';
import { Link } from '@components/atoms/Link/Link';
import { IconArrowCollapse } from '@assets/icon-arrow-collapse';
import { Navigation } from '../../molecules/navigation/Navigation';

export interface ContentsProps {
  title?: string;
  items: MenuItem[];
  expanded?: boolean;
}

export const Contents = ({ title = 'Inhaltsverzeichnis', items = [], expanded = false }: ContentsProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: expanded,
  });
  const renderTopLevelLink = (link) => (
    <Link href={link.url || ''}>
      <div className="w-full cursor-pointer py-2 font-bold no-underline hover:underline">{link.title}</div>
    </Link>
  );

  const renderLink = (link) =>
    link.children?.length > 0 ? (
      <>
        {renderTopLevelLink(link)}
        <ul className={'list-inside divide-y border-t'}>
          {link.children.map((child, index) => (
            <li
              key={index}
              className="bg-left bg-no-repeat py-2 pl-12"
              style={{ backgroundImage: 'url(/assets/icons/Icon-List-Arrow.svg)' }}
            >
              <Link href={child.url || ''}>{child.title}</Link>
            </li>
          ))}
        </ul>
      </>
    ) : (
      renderTopLevelLink(link)
    );

  return (
    items?.length > 0 && (
      <div className={'select-none border-y border-gray-650/25 bg-white px-4 drop-shadow-md'}>
        <div {...getToggleProps({ style: { marginRight: 4 } })}>
          <div className="flex gap-x-2 py-2 font-proxima-nova text-xl font-bold uppercase tracking-2px lg:text-xl">
            <span>{title}</span>
            <IconArrowCollapse open={isExpanded} classProps={{ root: 'w-7 h-7' }} />
          </div>
        </div>
        <div
          {...getCollapseProps({
            style: {},
          })}
        >
          <Navigation
            items={items}
            renderLink={renderLink}
            title={title}
            classProps={{
              listItem: 'w-full border-t text-base py-1',
            }}
          />
        </div>
      </div>
    )
  );
};
