import React from 'react';
import { ArticleTeaserCard } from '@components/organisms/ArticleTeaserCard/ArticleTeaserCard';

export default function El(Element) {
  if (!Element) return;
  return (
    <div className="tvm-scrollbar w-full min-w-0 overflow-x-auto pb-8 shadow-card-sm">
      <div className="grid h-full grid-flow-col gap-3">
        {Element.similar_articles?.map((article, index) => (
          <div className="h-full w-96" key={index}>
            <ArticleTeaserCard article={article} classProps={{ root: 'drop-shadow-none' }} />
          </div>
        ))}
      </div>
    </div>
  );
}
