/**
 * TV Guide
 */
export const TV_DATE_RANGES = [
  {
    name: 'Vormittag',
    firstHour: 6,
  },
  {
    name: 'Mittag',
    firstHour: 12,
  },
  {
    name: 'Nachmittag',
    firstHour: 17,
  },
  {
    name: 'Abend',
    firstHour: 20,
    firstMinute: 15,
    primeTime: true,
  },
  {
    name: 'Nacht',
    firstHour: 23,
  },
];
export const TV_DAY_COUNT = 14; // how many total days are shown
export const TIMESCALE = 3.6; // how many pixels per minute
export const FETCHDURATION = 4; // how many hours are fetched at a time
export const CHANNELCOUNT = 10;
export const INITIALCHANNELFETCHCOUNT = 10;

/**
 * Articles
 */

export const RESSORT_ARTICLES_PER_LOAD = 18;

/**
 * Feature flags
 */

export const SHOW_SHOWTIME_PLAY_BUTTON = false;
export const NEWSLETTER_ACTIVE = true;
