import React from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Button } from '@components/atoms/Button/Button';
import { EntityInfo } from '@components/atoms/EntityInfo/EntityInfo';
import { EntityScore } from '@components/atoms/EntityScore/EntityScore';
import { EntitySectionTitle } from '@components/atoms/EntitySectionTitle/EntitySectionTitle';
import { FallbackCard } from '@components/atoms/FallbackCard/FallbackCard';
import { Genre } from '@components/atoms/Genre/Genre';
import { Title } from '@components/atoms/Title';
import { WatchLink } from '@components/molecules/Watchlink/Watchlink';
import { Movie, Show } from '@src/lib/graphql/generated';

function getWatchLinks(entry: Show | Movie) {
  const providers = [];
  const watchLinks = entry?.links?.filter((link) => {
    if (!providers.includes(link?.provider)) {
      return providers.push(link.provider);
    }
  });

  return watchLinks.sort((l) => (l?.providerEntry?.sponsoring ? -1 : 1));
}

export interface ListicleListItemProps extends PropsWithClassProps {
  entry: Show | Movie;
  type: 'show' | 'movie';
  style?: 'compact' | 'default';
  sorting_type?: 'ranking' | 'numeration';
  place?: number;
}

export const ListicleListItem = ({ entry, type, style, sorting_type, place, classProps }: ListicleListItemProps) => {
  if (!entry) return;

  return style === 'compact' ? (
    <ListicleListItemCompact
      entry={entry}
      type={type}
      sorting_type={sorting_type}
      classProps={classProps}
      place={place}
    />
  ) : (
    <ListicleListItemDefault entry={entry} type={type} classProps={classProps} />
  );
};

const ListicleListItemDefault = ({ classProps, entry, type }: ListicleListItemProps) => {
  const cover_image = entry?.cover_image || entry?.backdrop_image;
  const backdrop_image = entry?.backdrop_image || entry?.cover_image;

  return (
    <div className="mx-auto max-w-xl lg:max-w-full">
      <div
        className={twMerge(
          'relative grid w-full overflow-hidden border-y-0.5 border-gray-650/25 bg-white shadow-card-sm dark:bg-gray-900 lg:min-h-[30vh] lg:grid-cols-[auto,1fr,auto] lg:gap-6 portrait-giant:!min-h-36',
          classProps?.root,
        )}
      >
        <div className="relative hidden aspect-cover h-full w-72 lg:block">
          <div className="absolute inset-0 -right-6 blur-xl">
            {cover_image ? (
              <div>
                <Image
                  src={cover_image.file?.path}
                  alt={entry.title}
                  fill
                  className="opacity-20"
                  sizes="256px"
                  copyright=""
                />
              </div>
            ) : (
              <FallbackCard />
            )}
          </div>
          <div className="relative size-full overflow-hidden p-12 pr-0">
            <div className="relative size-full">
              {cover_image ? (
                <Image
                  src={cover_image.file?.path}
                  alt={entry.title}
                  fill
                  className="rounded-md"
                  sizes="256px"
                  copyright=""
                />
              ) : (
                <FallbackCard />
              )}
            </div>
          </div>
        </div>
        <div className="px-4 pt-8 lg:hidden">
          <div className="relative aspect-video w-full overflow-hidden rounded-lg lg:hidden">
            {backdrop_image || cover_image ? (
              <Image
                src={backdrop_image.file?.path || cover_image.file?.path}
                alt={entry.title}
                fill
                copyright=""
                sizes="(min-width: 1560px) 200px, 85vw"
              />
            ) : (
              <FallbackCard />
            )}
          </div>
        </div>
        <div className="grid min-w-0 gap-6 p-4 lg:grid-rows-[1fr,auto] lg:py-12">
          <div>
            <div className="line-clamp-4 font-herokid text-2xl font-bold">{entry.title}</div>
            <div className="mt-2 flex flex-wrap gap-1">
              {entry.genres?.length > 0 &&
                entry.genres.map((genre) => <Genre genre={genre} key={genre.id} type="small" />)}
            </div>
            <div className="mt-2 line-clamp-5 [&>p]:inline" dangerouslySetInnerHTML={{ __html: entry.summary }}></div>
            <div className="text-center lg:hidden">
              <Button
                title="Mehr Info"
                link={encodeURI(`/${type.toLowerCase() === 'movie' ? 'filme' : 'serien'}/${entry.slug || entry.id}`)}
                buttonStyle="secondary"
                classProps={{ button: 'mt-6' }}
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-center pb-6 lg:justify-start lg:divide-x-0.5 lg:divide-gray-400">
            <EntityInfo value={entry.year} label="Jahr" classProps={{ root: 'px-6' }} />
            <EntityInfo
              value={entry.__typename === 'Movie' && entry.age_rating} // age_rating currently only exists for movies
              label="Alter"
              classProps={{ root: 'px-6' }}
            />
            <EntityInfo value={entry.runtime} unit="min" label="Spieldauer" classProps={{ root: 'px-6' }} />
          </div>
        </div>
        <div className="row-start-2 grid h-full items-stretch p-4 pt-6 text-center lg:row-start-auto lg:py-12 lg:pr-12">
          <div>
            {entry.tvm_score ? (
              <EntityScore score={entry.tvm_score} max={4} />
            ) : (
              <EntityInfo
                value={
                  entry?.tmdb_score
                    ? entry.tmdb_score?.toLocaleString('de-DE', { maximumFractionDigits: 1 }) + '%'
                    : '-'
                }
                label="TMDB Score"
              />
            )}
          </div>
          <div className="hidden self-end text-center lg:block">
            <Button
              title="Mehr Info"
              link={encodeURI(`/${type.toLowerCase() === 'movie' ? 'filme' : 'serien'}/${entry.slug || entry.id}`)}
              buttonStyle="secondary"
              classProps={{ button: 'mt-6' }}
            />
          </div>
        </div>
      </div>
      {entry?.links?.length > 0 && (
        <section id="providers" className="mx-auto mt-8 max-w-screen-lg text-center lg:text-left">
          <EntitySectionTitle heading={false} classProps={{ root: 'mt-0' }}>
            Jetzt ansehen
          </EntitySectionTitle>
          <div className="grid justify-center gap-2 lg:grid-cols-2">
            {getWatchLinks(entry).map((link) => (
              <WatchLink key={link.id} link={link} />
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

const ListicleListItemCompact = ({ classProps, entry, type, sorting_type, place }: ListicleListItemProps) => {
  const backdrop_image = entry?.backdrop_image || entry?.cover_image;

  return (
    <div className="relative mx-auto grid w-full max-w-xl gap-x-4 lg:max-w-full lg:grid-cols-[auto,1fr]">
      {place && (
        <div className="absolute z-20 ml-4 grid content-center items-center gap-1 rounded-b-lg bg-white/80 px-2 py-4 text-center uppercase backdrop-blur-md md:px-3 md:py-6 lg:relative lg:z-0 lg:bg-transparent lg:backdrop-blur-none">
          <div className="min-w-24 font-herokid text-5xl font-semibold leading-small text-gray-600 sm:text-6xl md:text-7xl">
            {place}
          </div>
          {sorting_type === 'ranking' && <div className="text-lg font-bold tracking-2px text-gray-400">Platz</div>}
        </div>
      )}
      <div
        className={twMerge(
          'relative w-full gap-6 bg-white p-8 shadow-card-sm dark:bg-gray-900 lg:grid lg:grid-cols-[auto,1fr,auto]',
          classProps?.root,
        )}
      >
        <div className="relative aspect-video w-full overflow-hidden rounded-md lg:w-80">
          {backdrop_image ? (
            <Image
              src={backdrop_image.file?.path}
              alt={entry.title}
              copyright=""
              fill
              className="rounded-lg"
              sizes="(min-width: 1560px) 256px, 85vw"
            />
          ) : (
            <FallbackCard />
          )}
        </div>
        <div className="mt-4 gap-6 lg:mt-0">
          <div>
            <Title
              level={2}
              classProps={{
                heading: 'line-clamp-4 font-herokid text-2xl font-bold',
              }}
            >
              {sorting_type === 'ranking' && <span className="hidden">{place}. Platz </span>}
              {entry.title}
            </Title>
            <div
              className="prose-sm mt-2 line-clamp-5 [&>p]:inline"
              dangerouslySetInnerHTML={{ __html: entry.summary }}
            ></div>
          </div>
        </div>
        <div className="mt-6 grid gap-y-3 text-center lg:mt-0 lg:items-stretch lg:gap-y-1">
          <div>
            {entry.tvm_score ? (
              <EntityScore score={entry.tvm_score} max={4} />
            ) : (
              <EntityInfo
                value={
                  entry?.tmdb_score
                    ? entry.tmdb_score?.toLocaleString('de-DE', { maximumFractionDigits: 1 }) + '%'
                    : '-'
                }
                label="TMDB Score"
              />
            )}
          </div>
          <div className="self-center">
            <EntityInfo value={entry.year} label="Jahr" />
          </div>
          <div className="inline-block self-end lg:m-0">
            <Button
              title="Mehr Info"
              link={encodeURI(`/${type.toLowerCase() === 'movie' ? 'filme' : 'serien'}/${entry.slug || entry.id}`)}
              buttonStyle="secondary"
            />
          </div>
        </div>
      </div>
      {entry?.links?.length > 0 && (
        <section id="providers" className="mx-auto mt-8 w-full max-w-screen-md text-center lg:col-start-2 lg:text-left">
          <EntitySectionTitle classProps={{ root: 'mt-0' }}>Jetzt ansehen</EntitySectionTitle>
          <div className="grid justify-center gap-2 lg:grid-cols-2">
            {getWatchLinks(entry).map((link) => (
              <WatchLink key={link.id} link={link} />
            ))}
          </div>
        </section>
      )}
    </div>
  );
};
