import React from 'react';
import { Interview } from '@vgn-medien-holding/vgn-react-components';

export default function El(Element) {
  return (
    <Interview
      classProps={{ interview: 'text-xl lg:px-32 px-8', interviewName: 'font-bold', interviewText: '' }}
      interview={Element.dialog}
    />
  );
}
