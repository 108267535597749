/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import {
  Link as LinkType,
  ListItem,
  ListUnordered,
  PropsWithClassProps,
} from '@vgn-medien-holding/vgn-react-components';
import { Link } from '@components/atoms/Link/Link';
import { Title } from '@components/atoms/Title';

export interface LinkBoxProps extends PropsWithClassProps<'linkBox' | 'heading' | 'list' | 'item' | 'link'> {
  externalLinks: LinkType[];
  title?: string;
}

export const LinkBox = ({ externalLinks, title }: LinkBoxProps) => {
  return (
    <div className={'mb-16'}>
      {title && (
        <Title level={2}>
          <span className={'text-xl'}>{title}</span>
        </Title>
      )}
      <ListUnordered
        classProps={{
          list: 'py-3 lg:p-3 pl-7 lg:pl-8 bg-white mt-4 lg:mt-6 pr-2 border-y-0.5 border-gray-650/25 shadow-card-sm',
        }}
      >
        {externalLinks.map((link, index) => (
          <ListItem classProps={{ item: 'list-disc py-2 underline text-primary' }} key={index}>
            <Link href={link.url} type={'primary'}>
              {link.title}
            </Link>
          </ListItem>
        ))}
      </ListUnordered>
    </div>
  );
};
