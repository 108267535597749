import React from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { FallbackCard } from '@components/atoms/FallbackCard/FallbackCard';
import { Link } from '@components/atoms/Link/Link';
import { Title } from '@components/atoms/Title';
import { AboElement } from '@components/organisms/AboElement/AboElement';

export default function El(Element) {
  const { headline, subscription_ad } = Element;

  if (!subscription_ad) return null;

  return (
    <>
      <div
        className={
          'relative mx-auto mb-24 mt-25 hidden h-[406px] w-full flex-row-reverse px-10 text-left drop-shadow-2xl md:flex'
        }
      >
        <div className={'ml-auto flex h-[314px] w-[427px] flex-col rounded-2xl border border-gray-650/25 bg-white p-8'}>
          <Title
            level={1}
            classProps={{ heading: 'font-herokid h-1/2 font-bold text-3xl leading-11 lg:text-4xl lg:leading-12' }}
          >
            {subscription_ad.title}
          </Title>
          <p className={'h-1/2'}>{subscription_ad.teaser}</p>
          <Link
            href={subscription_ad.link || ''}
            className={
              'rounded-full border border-primary p-2 text-center font-herokid text-2xs font-bold uppercase tracking-widest text-primary'
            }
          >
            {subscription_ad.button_text}
          </Link>
        </div>
        <div className={'absolute left-6 m-auto h-full w-[362px]'}>
          {subscription_ad?.asset?.src ? (
            <Image
              src={subscription_ad.asset.src}
              alt={headline}
              width={subscription_ad.asset.width}
              height={subscription_ad.asset.height}
              copyright=""
              className="m-auto py-10"
            />
          ) : (
            <FallbackCard />
          )}
        </div>
      </div>
      <AboElement
        classProps={{ root: 'md:hidden' }}
        imageSrc={subscription_ad.asset.src}
        imageAlt={headline}
        title={subscription_ad.title}
        descriptionText={subscription_ad.teaser}
        ctaText={subscription_ad.button_text}
        link={subscription_ad.link}
      />
    </>
  );
}
