import React from 'react';

export default function El(Element) {
  if (!Element) return;
  const { quote } = Element;
  const text = '&bdquo;' + quote?.text + '&rdquo;';
  return (
    <div className="mx-auto max-w-screen-sm">
      <div
        dangerouslySetInnerHTML={{ __html: text }}
        className="hyphenate text-center text-2xl italic underline decoration-white decoration-8 [&>p]:inline"
      ></div>
      <div className="mt-4 text-center text-sm font-bold uppercase tracking-2px">{quote?.person?.name}</div>
    </div>
  );
}
