import React, { FC } from 'react';
import { KTA } from '@vgn-medien-holding/vgn-react-components';
import { InfoList } from '@components/organisms/InfoList/InfoList';

export interface InfoBoxProps {
  entries: KTA[];
}

export const InfoBox: FC<InfoBoxProps> = ({ entries }) => {
  return (
    entries.length > 0 && (
      <section>
        <div
          className={'mt-10 border-y border-gray-650/25 bg-white p-12 text-gray-700 shadow-card-sm md:px-28 md:py-14'}
        >
          <InfoList classProps={{ item: 'py-6', info: 'pt-4 divide-y divide-gray-650/25' }} entries={entries} />
        </div>
      </section>
    )
  );
};
