import React from 'react';
import dynamic from 'next/dynamic';

const CeQualifio = dynamic(
  () => import('@vgn-medien-holding/vgn-fe-components').then((module) => module.ContentElementQualifio),
  { ssr: false },
);

export const ContentElementQualifio = (element) => {
  return <CeQualifio element={element} />;
};
