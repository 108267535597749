export const sortChannelsbyFavorites = (channels) => {
  const orderedChannels = [];
  const lsChannels = localStorage.getItem('channelFavorites');
  const favoriteChannels = JSON.parse(lsChannels);

  if (!favoriteChannels) return channels;

  favoriteChannels.forEach((e) => {
    const channel = channels.find((c) => c.id === e);
    orderedChannels.push(channel);
  });

  const rest = channels?.filter((e) => !favoriteChannels.includes(e.id)) || [];
  return [...orderedChannels, ...rest];
};

/**
 * Get a nested object value dynamically
 * @param obj  - Input Object
 * @param objPath - Array of strings which is being resolved as the path to the nested value
 * @returns the nested value
 */
export const getObjectValue = (obj, objPath) => {
  if (!obj) return null;
  let entry = obj;
  for (const k of objPath) {
    entry = entry[k];
  }
  return entry;
};

export const isUuid = (str) => {
  const rgx = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return rgx.test(str);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUniqueEntries = (arr: any[], path: string[]) => {
  return [
    ...arr
      .reduce((a, c) => {
        a.set(getNestedEntry(c, path), c);
        return a;
      }, new Map())
      .values(),
  ];
};

export const getNestedEntry = (obj, path: string[]) => {
  return path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), obj);
};
