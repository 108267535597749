import React, { FC } from 'react';
import { FAQ } from '@vgn-medien-holding/vgn-react-components';
import { FAQList } from '../FAQList/FAQList';

export interface FAQBoxProps {
  faqs: FAQ[];
}

export const FAQBox: FC<FAQBoxProps> = ({ faqs }) => {
  return (
    faqs.length > 0 && (
      <section>
        <div
          className={'border-y border-gray-650/25 bg-white px-4 py-12 text-gray-700 shadow-card-sm md:px-28 md:py-14'}
        >
          <FAQList faqs={faqs} />
        </div>
      </section>
    )
  );
};
