import React from 'react';
import { Contents } from '@components/organisms/Contents/Contents';

export default function El(Element) {
  Element.list.forEach((element) => {
    element.title = element.headline;
    element.url = '#' + element['anchor'];
    if (element.children) {
      element.children.forEach((child) => {
        child.title = child.headline;
        child.url = '#' + child['anchor'];
      });
    }
  });
  return (
    <div className="content-element-padding">
      <Contents items={Element.list} expanded={Element.list_expanded} />
    </div>
  );
}
