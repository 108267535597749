import React, { FC } from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export interface ImageCardProps
  extends PropsWithClassProps<'ImageCard' | 'Image' | 'ImageDescription' | 'ImageCopyright' | 'ImageText'> {
  imageSrc: string;
  imageAlt: string;
  objectFit?: string;
  objectPosition?: string;
  description?: string;
  copyright?: string;
  width?: number;
  height?: number;
  sizes?: string;
  fill?: boolean;
  priority?: boolean;
}

export const ImageCard: FC<ImageCardProps> = ({
  classProps,
  imageSrc,
  imageAlt,
  description,
  copyright,
  width,
  fill,
  height,
  sizes,
  priority,
}) => {
  return (
    imageSrc && (
      <figure role={'group'} className={twMerge('w-full bg-white shadow-card-sm', classProps?.root)}>
        <div className={twMerge('relative', classProps?.ImageCard)}>
          <Image
            src={imageSrc}
            className={classProps?.Image}
            alt={imageAlt}
            width={width}
            height={height}
            priority={priority}
            fill={fill}
            copyright={copyright}
            sizes={sizes}
          />
        </div>
        <figcaption className={description || copyright ? 'prose-caption w-full px-3 py-2' : 'hidden'}>
          {description && <span className={'pr-2 [&>p]:inline'} dangerouslySetInnerHTML={{ __html: description }} />}
          {copyright && <span className={'text-gray-500'} dangerouslySetInnerHTML={{ __html: '© ' + copyright }} />}
        </figcaption>
      </figure>
    )
  );
};
