import React, { FC } from 'react';
import { KTA } from '@vgn-medien-holding/vgn-react-components';
import { InfoList } from '@components/organisms/InfoList_ver_2/InfoList';

export interface InfoBoxProps {
  entries: KTA[];
}

export const InfoBox: FC<InfoBoxProps> = ({ entries }) => {
  return (
    entries.length > 0 && (
      <section>
        <InfoList classProps={{ item: 'p-12 flex flex-col', list: 'my-8', info: '' }} entries={entries} />
      </section>
    )
  );
};
