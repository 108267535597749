import { ContentElementTableProps } from '@vgn-medien-holding/vgn-fe-components';

const classProps = {
  root: 'w-full max-w-full overflow-x-auto',
  table: 'text-left min-w-full table-auto',
  entry: 'px-3 py-1 article-base bg-white border-b-2 border-gray-100 group-last:border-none whitespace-nowrap',
  lastRowEntry: '',
  td: '',
  th: 'font-bold py-2',
  thContent: 'flex gap-2 items-center',
  caption: 'body-sm mt-1.5 mx-2 text-center text-gray-700',
} as ContentElementTableProps['classProps'];

export default classProps;
