import React, { Children, PropsWithChildren, cloneElement, useState } from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { IconNext } from '@assets/icon-next';
import { IconPrev } from '@assets/icon-prev';

export interface SliderProps
  extends PropsWithChildren,
    PropsWithClassProps<'button' | 'slide' | 'buttonLeft' | 'buttonRight' | 'slideContainer'> {
  CustomButtonLeft?: React.FC;
  CustomButtonRight?: React.FC;
  buttonsOverlaying?: boolean;
}

export const Slider = ({
  CustomButtonLeft,
  CustomButtonRight,
  buttonsOverlaying,
  children,
  classProps,
}: SliderProps) => {
  const slideElements: React.ReactNode[] = Children.toArray(children);

  const [slideIndex, setSlideIndex] = useState(0);
  if (slideElements?.length < 1) return;

  function changeSlide(Count) {
    const nextIndex = slideIndex + Count;
    setSlideIndex(nextIndex > slideElements.length - 1 ? 0 : nextIndex < 0 ? slideElements.length - 1 : nextIndex);
  }

  const buttonStyleLeft = twMerge(
    'left-0 h-full grid items-center',
    buttonsOverlaying && 'absolute inset-y-0 z-20',
    classProps?.button,
    classProps?.buttonLeft,
  );
  const buttonStyleRight = twMerge(
    'right-0 h-full grid items-center',
    buttonsOverlaying && 'absolute inset-y-0 z-20',
    classProps?.button,
    classProps?.buttonRight,
  );

  return (
    <div className={twMerge('relative grid w-full grid-cols-[auto,1fr,auto]', classProps?.root)}>
      <div>
        <div
          className={buttonStyleLeft}
          onClick={() => {
            changeSlide(-1);
          }}
        >
          {CustomButtonLeft ? <CustomButtonLeft /> : <SliderButton />}
        </div>
      </div>
      <div className={twMerge('w-full overflow-hidden', classProps?.slideContainer)}>
        <div
          className={twMerge('flex transition-transform duration-300', classProps?.slide)}
          style={{ transform: `translateX(-${100 * slideIndex}%)` }}
        >
          {slideElements.map((slide: React.ReactElement, index) =>
            cloneElement(slide, { className: twMerge('flex-none w-full h-full', slide.props.className), key: index }),
          )}
        </div>
      </div>
      <div>
        <div
          className={buttonStyleRight}
          onClick={() => {
            changeSlide(1);
          }}
        >
          {CustomButtonRight ? <CustomButtonRight /> : <SliderButton type="next" />}
        </div>
      </div>
    </div>
  );
};

export interface SliderButtonProps {
  type?: string;
}

export const SliderButton = ({ type }: SliderButtonProps) => {
  if (type === 'next') {
    return (
      <div className="cursor-pointer">
        <IconNext classProps={{ root: 'bg-white hover:opacity-100 opacity-60' }} />
      </div>
    );
  } else {
    return (
      <div className="cursor-pointer">
        <IconPrev classProps={{ root: 'bg-white hover:opacity-100 opacity-60' }} />
      </div>
    );
  }
};
