import React from 'react';

export default function El(Element) {
  return (
    <div
      className="prose-sm md:prose dark:!text-white dark:prose-strong:text-white lg:px-32"
      dangerouslySetInnerHTML={{ __html: Element.text }}
    />
  );
}
