import React from 'react';
import { IconDownload } from '@assets/icon-download';

export default function El(Element) {
  const downloadElement = Element.files?.[0]?.path;
  const style = 'prose inline-block lg:px-32 text-primary underline flex justify-start items-start';

  return downloadElement ? (
    <a className={style} href={downloadElement} download target="_blank">
      <IconDownload classProps={{ root: 'inline mr-0.5 h-6 w-6 min-w-[1.5rem] mt-0.5' }} />
      <span className="[&>p]:m-0" dangerouslySetInnerHTML={{ __html: Element.teaser }} />
    </a>
  ) : (
    <div className={style} dangerouslySetInnerHTML={{ __html: Element.teaser }}></div>
  );
}
