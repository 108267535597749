import React, { FC } from 'react';
import { FacebookEmbed, InstagramEmbed, TwitterEmbed } from 'react-social-media-embed';

export interface SocialEmbedsProps {
  network: string;
  id: string;
}

export const SocialEmbeds: FC<SocialEmbedsProps> = ({ network, id }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {network === 'facebook' ? (
        <FacebookEmbed url={id} width={400} />
      ) : network === 'instagram' ? (
        <InstagramEmbed url={id} width={400} captioned />
      ) : network === 'instagram Reel' ? (
        <InstagramEmbed url={id} width={400} captioned />
      ) : network === 'twitter' ? (
        <TwitterEmbed url={id} width={400} />
      ) : (
        ''
      )}
    </div>
  );
};
