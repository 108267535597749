import React from 'react';
import dynamic from 'next/dynamic';
import { VideoCard } from '@components/molecules/VideoCard/VideoCard';

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

export default function El(Element) {
  const videoSrc = {
    file: Element.path,
    youtube: 'https://www.youtube.com/watch?v=' + Element.youtube,
    jw: `https://cdn.jwplayer.com/players/${Element.jw_player}-${process.env.NEXT_PUBLIC_JW_PLAYER}.html`,
  };

  if (!Object.keys(videoSrc).includes(Element.video_source)) return;

  return (
    <div className="relative size-full lg:px-32">
      <VideoCard
        description={Element.caption}
        copyright={Element.copyright}
        classProps={{
          root: 'rounded-lg overflow-hidden',
          videoCard: 'aspect-video',
        }}
      >
        {['youtube', 'file'].includes(Element.video_source) ? (
          <ReactPlayer url={videoSrc[Element.video_source]} controls width="100%" height="100%" />
        ) : Element.video_source === 'jw' ? (
          <iframe
            src={videoSrc[Element.video_source]}
            width="480"
            height="360"
            title="JW Player"
            allowFullScreen
          ></iframe>
        ) : (
          <div className={'border border-primary'}>
            To be implemented: Source of Video not supported yet ({Element.video_source})
          </div>
        )}
      </VideoCard>
    </div>
  );
}
