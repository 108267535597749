import React from 'react';
import { InfoBox } from '@components/organisms/InfoBox/InfoBox';
import { InfoBox as InfoBox2 } from '@components/organisms/InfoBox_ver_2/InfoBox';

export default function El(Element) {
  return Element.style == 'style_1' ? (
    <InfoBox entries={Element.entries} />
  ) : Element?.style == 'style_2' ? (
    <InfoBox2 entries={Element.entries} />
  ) : (
    ''
  );
}
