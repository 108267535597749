import React, { useEffect, useState } from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { Button } from '@components/atoms/Button/Button';
import { EntityInfo } from '@components/atoms/EntityInfo/EntityInfo';
import { Spinner } from '@components/atoms/Spinner/Spinner';
import { Title } from '@components/atoms/Title';
import { calculateAge } from '@utils/dateHelpers';
import { GetPersonForProfileDocument } from '@lib/graphql/generated';
import { ssrQuery } from '@lib/graphql/urql';

const fetchPerson = async (id) => {
  const { data } = await ssrQuery({
    query: GetPersonForProfileDocument,
    variables: { id },
  });
  return data?.person;
};

export default function El(Element, ExtraData) {
  const [person, setPerson] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const personData = ExtraData.find((e) => e.id === Element.person?.id);
    if (personData) {
      setPerson(personData);
    } else {
      setLoading(true);
      fetchPerson(Element.person.id).then((res) => {
        setPerson(res);
        setLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!Element?.person?.id) return;

  return (
    <>
      {loading ? (
        <div className="grid min-h-[30vh] w-full place-items-center portrait-giant:min-h-40">
          <Spinner />
        </div>
      ) : (
        <section className="mx-auto max-w-[1090px]">
          <div className="gap-10 border-y bg-white p-6 pb-8 text-center shadow-card-sm dark:border-gray-650/25 dark:bg-gray-820/70 sm:p-12 lg:justify-start lg:text-left">
            <div className="grid content-center gap-12 lg:grid-cols-[auto,1fr]">
              <div className="p-8">
                <div className="relative mx-auto size-52 overflow-hidden rounded-full">
                  <div>
                    {person?.image?.file?.path && (
                      <Image
                        src={person.image.file.path}
                        alt={person.name}
                        fill
                        copyright={person.image.file.copyright}
                        sizes="400px"
                      />
                    )}
                  </div>
                </div>
                <div className="mt-8 grid justify-center gap-2">
                  {person?.movies?.data?.length > 0 && (
                    <EntityInfo
                      classProps={{ value: 'text-xl', label: 'text-gray-400' }}
                      value={person?.movies?.data?.length}
                      label="Auftritte"
                    />
                  )}
                  <EntityInfo
                    classProps={{ value: 'text-xl', label: 'text-gray-400' }}
                    value={person?.gender || 'Männlich'}
                    label="Geschlecht"
                  />
                  {person?.born_at && (
                    <EntityInfo
                      classProps={{ value: 'text-xl', label: 'text-gray-400' }}
                      value={calculateAge(person?.born_at)}
                      label="Alter"
                    />
                  )}
                  {person?.movies?.data?.length > 0 && (
                    <EntityInfo
                      classProps={{ value: 'text-xl', label: 'text-gray-400' }}
                      value={person?.movies?.data?.length}
                      label="Filme"
                    />
                  )}
                  {person?.shows?.data?.length > 0 && (
                    <EntityInfo
                      classProps={{ value: 'text-xl', label: 'text-gray-400' }}
                      value={person?.shows?.data?.length}
                      label="Serien"
                    />
                  )}
                </div>
                <div className="text-center">
                  <Button
                    title="Mehr Info"
                    link={encodeURI(`/personen/${person?.slug || person?.id}`)}
                    buttonStyle="secondary"
                    classProps={{ button: 'mt-6' }}
                  />
                </div>
              </div>
              <div className="justify-center text-left">
                <Title level={2} classProps={{ heading: 'text-3xl lg:text-4xl' }}>
                  {person?.name}
                </Title>
                <div dangerouslySetInnerHTML={{ __html: person?.summary }} className="mt-8 text-lg md:text-xl"></div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
