import { sortedChannels } from '@utils/sortedChannels';
import { GetChannelsDocument } from '../generated';
import { ssrQuery } from '../urql';

export async function fetchChannels() {
  const { data } = await ssrQuery({
    query: GetChannelsDocument,
    variables: {},
  });

  const channelList = (data.channels?.data ?? []).map((channel) => {
    const channelStatic = sortedChannels.filter((e) => e.id === channel.id);
    return {
      ...channel,
      localLogo: channelStatic.length === 1 ? channelStatic[0].localLogo : null,
    };
  });

  return { data: channelList };
}
