/* This example requires Tailwind CSS v2.0+ */
import React, { useState, Fragment } from 'react';
import Script from 'next/script';
import { PropsWithClassProps, FAQ } from '@vgn-medien-holding/vgn-react-components';
import { IconMinus } from '@assets/icon-minus';
import { IconPlus } from '@assets/icon-plus';

export interface FAQBoxProps
  extends PropsWithClassProps<'faq' | 'list' | 'item' | 'question' | 'answer' | 'iconMinus' | 'iconPlus'> {
  faqs: FAQ[];
}

export const FAQList = ({ classProps, faqs }: FAQBoxProps) => {
  const [question, setquestion] = useState(-1);

  const structuredFaqs = faqs.map((e) => {
    return {
      '@type': 'Question',
      name: e.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: e.answer,
      },
    };
  });

  return (
    <div className={classProps?.faq}>
      <Script
        type={'application/ld+json'}
        id={'faq-structured-data'}
        strategy={'afterInteractive'}
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: structuredFaqs,
          }),
        }}
      ></Script>
      <dl className={classProps?.root}>
        {faqs.map((faq, index) => (
          <Fragment key={index}>
            <dt
              className="grid cursor-pointer select-none grid-cols-[1fr,auto] items-center border-y-0.5 border-gray-650/25 py-2.5 text-base font-bold"
              onClick={() => (question === index ? setquestion(null) : setquestion(index))}
            >
              <div>{faq.question}</div>
              <div>{question === index ? <IconMinus /> : <IconPlus />}</div>
            </dt>
            <dd className="py-2 text-gray-500">
              {question === index ? (
                <span className={classProps?.answer} dangerouslySetInnerHTML={{ __html: faq.answer }} />
              ) : (
                ''
              )}
            </dd>
          </Fragment>
        ))}
      </dl>
    </div>
  );
};
