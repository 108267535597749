import React from 'react';
import {
  ContentElementTable as CeTable,
  ContentElementTableProps as CeTableProps,
} from '@vgn-medien-holding/vgn-fe-components';
import elementStyle from './styles/default';

export type ContentElementTableProps = CeTableProps;

export default function El(element: CeTableProps['element']) {
  return (
    <div className="md:px-16 xl:px-32">
      <CeTable element={element} classProps={elementStyle} />
    </div>
  );
}
