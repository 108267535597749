import React, { FC, PropsWithChildren } from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export interface VideoCardProps extends PropsWithClassProps<'videoCard' | 'videoText'>, PropsWithChildren {
  description?: string;
  copyright?: string;
}

export const VideoCard: FC<VideoCardProps> = ({ classProps, description, copyright, children }) => {
  return (
    <figure role={'group'} className={twMerge('w-full bg-white shadow-card-sm', classProps?.root)}>
      <div className={twMerge('relative', classProps?.videoCard)}>{children}</div>
      <figcaption
        className={description || copyright ? 'prose-caption w-full px-3 py-2 ' + classProps?.videoText : 'hidden'}
      >
        {description && <span className={'pr-2 [&>p]:inline'} dangerouslySetInnerHTML={{ __html: description }} />}
        {copyright && <span className={'text-gray-500'} dangerouslySetInnerHTML={{ __html: '© ' + copyright }} />}
      </figcaption>
    </figure>
  );
};
