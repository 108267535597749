import React from 'react';
import { PropsWithClassProps, KTA } from '@vgn-medien-holding/vgn-react-components';

export interface InfoListProps extends PropsWithClassProps<'info' | 'list' | 'item'> {
  entries: KTA[];
}

export const InfoList = ({ classProps, entries }: InfoListProps) => {
  return (
    <div className={classProps?.info}>
      {entries?.map((info, index) => (
        <div key={index} className={classProps?.list}>
          <div className={'border-y border-gray-650/25 bg-white text-gray-700 shadow-card-sm'}>
            <div key={index} className={classProps?.item} dangerouslySetInnerHTML={{ __html: info.text }} />
          </div>
        </div>
      ))}
    </div>
  );
};
