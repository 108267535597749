import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps, Link } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { TvChannel } from '@src/lib/graphql/generated';
import { getChannelLogo } from '@src/utils/sortedChannels';

export interface ChannelProps extends PropsWithClassProps {
  channel: TvChannel;
  showInitially?: boolean;
}

export const Channel = ({ channel, showInitially, classProps }: ChannelProps) => {
  const channelElement = channel;

  const logoPath = useMemo(() => getChannelLogo(channel?.id), [channel.id]);
  const { ref: inViewRef, inView } = useInView({
    rootMargin: '50px 350px',
    triggerOnce: true,
  });

  const itemVisible = useMemo(() => inView || showInitially, [inView, showInitially]);

  if (!channelElement) return;
  return (
    <Link
      href={'/sender/' + (channelElement.slug || channelElement.id)}
      classProps={{
        root: twMerge(
          'streaming-entry-bg min-h-36 min-w-36 flex aspect-square w-full cursor-pointer items-center justify-center rounded-lg border-0.5 border-transparent p-2 text-center text-sm font-bold uppercase tracking-2px text-white transition-colors duration-150 hover:border-primary',
          classProps?.root,
        ),
      }}
      ref={inViewRef}
    >
      {!itemVisible ? null : logoPath ? (
        <Image
          src={logoPath}
          alt={channel.name}
          title={channel.name}
          sizes="96px"
          fill
          copyright=""
          decoding="async"
          loading="lazy"
          classProps={{ root: 'h-auto max-h-full w-24 object-fit p-4', container: 'flex items-center' }}
        />
      ) : (
        <span className="hyphenate">{channel.name}</span>
      )}
    </Link>
  );
};
