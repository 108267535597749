import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export interface IconArrowProps extends PropsWithClassProps {
  open?: boolean;
}

export const IconArrowCollapse = ({ open, classProps }: IconArrowProps) => {
  const svgStyle = twMerge(
    'w-6 h-6 origin-center transition-transform duration-200 rotate-180',
    classProps?.root,
    open && 'rotate-0',
  );
  return (
    <svg className={svgStyle} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.25 13.5L12.5 9.75L8.75 13.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
