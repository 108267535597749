import React from 'react';
import { Listicles } from '@components/organisms/Listicles/Listicles';

export default function El(Element, ExtraData) {
  if (Element?.items?.length < 1) return;
  return (
    <div>
      <Listicles
        isArticle
        display={Element.listicle_style}
        listicle_type="MANUAL"
        listicle_sorting_type={Element.listicle_type}
        listicle_sorting_direction={Element.listicle_sorting}
        items={Element.items}
        itemData={ExtraData}
        query=""
        dataSet={Element.dataSet ?? []}
      />
    </div>
  );
}
