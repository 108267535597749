import {
  GetEntitiesDocument,
  GetEpisodesDocument,
  GetGenresDocument,
  GetMoviesDocument,
  GetMoviesInCinemaDocument,
  GetMoviesNewDocument,
  GetMoviesPopularDocument,
  GetPersonsDocument,
  GetProvidersDocument,
  GetProvidersListDocument,
  GetShowsDocument,
} from '../generated';
import { ssrQuery } from '../urql';
import { fetchChannels } from './fetchChannels';

export const movieListicleQuery = ({ limit, query, items, onlyWithAsset }) => {
  return async () => {
    if (query === 'cinema') {
      const { data } = await ssrQuery({
        query: GetMoviesInCinemaDocument,
        variables: { first: limit, onlyWithAsset: true, includeShowtimes: false },
      });
      return data?.moviesInCinema;
    } else if (query === 'popularity') {
      const { data } = await ssrQuery({
        query: GetMoviesPopularDocument,
        variables: { first: limit, page: 1, onlyWithAsset: true },
      });
      return data?.moviesPopularity;
    } else if (query === 'new') {
      const { data } = await ssrQuery({
        query: GetMoviesNewDocument,
        variables: { first: limit, page: 1, onlyWithAsset: true },
      });
      return data?.moviesNew;
    } else if (query === 'manual') {
      const { data } = await ssrQuery({
        query: GetEntitiesDocument,
        variables: { ids: items, search: null, first: 999, page: 1, onlyWithAsset },
      });
      return data?.entities;
    } else {
      const { data } = await ssrQuery({
        query: GetMoviesDocument,
        variables: { first: limit, page: 1, onlyWithAsset: true },
      });
      return data?.movies;
    }
  };
};

export const showListicleQuery = ({ limit, query, items, onlyWithAsset }) => {
  return async () => {
    if (query === 'popularity') {
      const { data } = await ssrQuery({
        query: GetShowsDocument,
        variables: { first: limit, page: 1, onlyWithAsset: true },
      });
      return data?.tvshows;
    } else if (query === 'new') {
      const { data } = await ssrQuery({
        query: GetShowsDocument,
        variables: { first: limit, page: 1 },
      });
      return data?.tvshows;
    } else if (query === 'manual') {
      const { data } = await ssrQuery({
        query: GetEntitiesDocument,
        variables: { ids: items, search: null, first: 999, page: 1, onlyWithAsset },
      });
      return data?.entities;
    } else {
      const { data } = await ssrQuery({
        query: GetShowsDocument,
        variables: { first: limit, page: 1, onlyWithAsset: true },
      });
      return data?.tvshows;
    }
  };
};

export const episodeListicleQuery = ({ limit }) => {
  return async () => {
    const { data } = await ssrQuery({
      query: GetEpisodesDocument,
      variables: { first: limit, page: 1 },
    });
    return data?.episodes;
  };
};

export const genreListicleQuery = ({ limit }) => {
  return async () => {
    const { data } = await ssrQuery({
      query: GetGenresDocument,
      variables: { first: limit, page: 1 },
    });
    return data?.genres;
  };
};

export const providerListicleQuery = ({ limit, query, items }) => {
  return async () => {
    if (query === 'manual') {
      const { data } = await ssrQuery({
        query: GetProvidersListDocument,
        variables: { ids: items },
      });
      return { data: data?.providersList };
    } else {
      const { data } = await ssrQuery({
        query: GetProvidersDocument,
        variables: { first: limit, page: 1 },
      });
      return data?.providers;
    }
  };
};

export const personListicleQuery = ({ limit }) => {
  return async () => {
    const { data } = await ssrQuery({
      query: GetPersonsDocument,
      variables: { first: limit, page: 1 },
    });
    return data?.persons;
  };
};

export const collectionEntryListicleQuery = ({ limit, items, onlyWithAsset }) => {
  return async () => {
    const { data } = await ssrQuery({
      query: GetEntitiesDocument,
      variables: { ids: items, search: null, first: limit || 0, page: 1, onlyWithAsset },
    });
    return data?.entities;
  };
};

export const fetchListicleData = async (listicle_type, limit, query, items, onlyWithAsset) => {
  const queries = {
    MOVIE: movieListicleQuery({ limit, query, items, onlyWithAsset }),
    SHOW: showListicleQuery({ limit, query, items, onlyWithAsset }),
    EPISODE: episodeListicleQuery({ limit }),
    GENRE: genreListicleQuery({ limit }),
    PROVIDER: providerListicleQuery({ limit, query, items }),
    PERSON: personListicleQuery({ limit }),
    COLLECTION_ENTRY: collectionEntryListicleQuery({ limit, items, onlyWithAsset }),
    MANUAL: async () => {
      const { data } = await ssrQuery({
        query: GetEntitiesDocument,
        variables: { ids: items, search: null, first: limit || 0, page: 1, onlyWithAsset },
      });
      return data?.entities;
    },
    CHANNEL: async () => {
      const allChannels = await fetchChannels();

      if (query === 'tv') {
        const filteredSortedChannels = allChannels.data.filter((e) => e.id);

        return { data: { data: filteredSortedChannels.slice(0, limit) } };
      } else if (query === 'manual' || query === 'settings') {
        if (items?.length < 1) return;
        const channels = [];
        items.forEach((e) => {
          const channel = allChannels.data.find((s) => s.id === e);
          if (channel) {
            channels.push(channel);
          }
        });
        return { data: { data: channels } };
      } else {
        const filteredSortedChannels = allChannels.data.filter((e) => e.id);
        return filteredSortedChannels.slice(0, limit);
      }
    },
  };

  return queries[listicle_type] ? await queries[listicle_type]() : null;
};
