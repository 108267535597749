import React, { useState } from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { Button } from '@components/atoms/Button/Button';
import { Link } from '@components/atoms/Link/Link';
import { Newsletter as NewsletterType, SubscribeToNewsletterDocument } from '@src/lib/graphql/generated';
import { useMutation } from '@lib/graphql/urql';

export interface NewsletterProps extends PropsWithClassProps {
  newsletter?: NewsletterType;
  hasBackground?: boolean;
}

export const Newsletter = ({ newsletter, classProps, hasBackground }: NewsletterProps) => {
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
  });

  const handleChange = (event, field) => {
    const newForm = Object.assign({}, form);
    newForm[field] = event.target.value;
    setForm(newForm);
  };

  const [, subscribeNewsletter] = useMutation(SubscribeToNewsletterDocument);

  if (!newsletter) return null;

  const singleNewsletter = Array.isArray(newsletter) ? newsletter[0] : newsletter;

  const submitForm = ({ firstname, lastname, email }) => {
    subscribeNewsletter({
      newsletter: singleNewsletter.id,
      firstname: firstname,
      lastname: lastname,
      email: email,
      acceptMarketing: true,
    }).then((result) => {
      window.location.href = result?.data?.subscribeToNewsletter?.redirect_url;
    });
  };

  return (
    <div className={`relative mx-4 overflow-hidden rounded-xl px-5 py-14 ${classProps?.root}`}>
      {hasBackground && (
        <Image
          src="/assets/tvm_assets/bg-newsletter.png"
          fill
          alt=""
          sizes="100vw"
          copyright=""
          classProps={{ container: 'static', root: 'object-cover' }}
        />
      )}
      <div className="relative mx-auto flex max-w-screen-lg items-center backdrop-blur-sm">
        <div className="flex flex-col gap-x-5 gap-y-10 rounded-lg bg-white/80 p-6 dark:border-0.5 dark:border-gray-650/60 dark:bg-gray-820/70 lg:flex-row lg:p-12">
          <div className="flex-1 pr-16 md:basis-1/2">
            <h2 className={'font-herokid text-3xl font-medium leading-header text-black dark:text-white md:text-4xl'}>
              E-Mail für dich!
            </h2>
            <p className="pt-2.5 text-base leading-body text-gray-600 dark:text-gray-400">
              Abonniere unseren Newsletter und erhalte Infos zu Highlights, Neuerscheinungen sowie Filmen und Serien,
              die nur mehr kurz verfügbar sind.
            </p>
          </div>
          <div className="md:basis-1/2">
            <form
              className="flex flex-col"
              onSubmit={($event) => {
                $event.preventDefault();
                submitForm(form);
              }}
            >
              <div className="mb-4 grid grid-cols-1 gap-6 md:grid-cols-2">
                <div>
                  <input
                    id="first_name"
                    name="first_name"
                    type="name"
                    autoComplete="given-name"
                    required
                    className="w-full rounded-full border border-gray-500 bg-transparent p-3 text-sm leading-body placeholder:text-black dark:border-gray-300 dark:placeholder:text-white lg:px-2.5"
                    placeholder="Vorname "
                    value={form.firstname}
                    onChange={($event) => {
                      handleChange($event, 'firstname');
                    }}
                  />
                </div>
                <div>
                  <input
                    id="last_name"
                    name="last_name"
                    type="name"
                    autoComplete="family-name"
                    required
                    className="w-full rounded-full border border-gray-500 bg-transparent p-3 text-sm leading-body placeholder:text-black dark:border-gray-300 dark:placeholder:text-white lg:px-2.5"
                    placeholder="Nachname"
                    value={form.lastname}
                    onChange={($event) => {
                      handleChange($event, 'lastname');
                    }}
                  />
                </div>
              </div>

              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="w-full rounded-full border border-gray-500 bg-transparent py-3 text-sm leading-body placeholder:text-black dark:border-gray-300 dark:placeholder:text-white lg:px-2.5"
                placeholder="E-Mail-Adresse"
                value={form.email}
                onChange={($event) => {
                  handleChange($event, 'email');
                }}
              />
              <div className={'pt-6'}>
                <input type="checkbox" id="dsgvo" name="dsgvo" className={'rounded-full bg-transparent'} required />
                <label htmlFor="dsgvo" className={'ml-1 text-sm'}>
                  Ja, ich stimme zu, dass die VGN Medien Holding GmbH, die VGN Digital GmbH, die Verlagsgruppe News
                  Medienservice GmbH, WOMAN GmbH & Co KG und GUSTO GmbH & Co KG die von mir angegebenen
                  personenbezogenen Daten (Name, Titel, Anschrift, E-Mailadresse, Telefonnummer, Geburtsdatum, Fotos)
                  zwecks Übermittlung von Informationsmaterial über deren Magazine und Online-Medien, für
                  Marketingmaßnahmen (Gewinnspiele, Veranstaltungen, Newsletter für deren Waren und Dienstleistungen)
                  sowie für Abo- und Sonderwerbeaktionen, verarbeitet und mich zu den vorgenannten Zwecken via SMS,
                  E-Mail, Telefon und Post kontaktiert. Diese Einwilligung kann ich jederzeit unter
                  <a href="mailto:datenschutz@vgn.at">
                    <span className={'ml-1 text-black underline dark:text-white'}>datenschutz@vgn.at </span>
                  </a>
                  widerrufen. Informationen zur Verarbeitung meiner personenbezogenen Daten bzw. weitere Informationen
                  zum Abbestellen erhalte ich in der Datenschutzerklärung, abrufbar unter
                  <Link href="https://www.vgn.at/pdf/Datenschutzpolicy.pdf">
                    <span className={'ml-1 text-black underline dark:text-white'}>Datenschutzpolicy</span>
                  </Link>
                  .
                </label>
              </div>
              <Button
                title="Newsletter abonnieren"
                buttonStyle="primary"
                hasAction={true}
                classProps={{ button: '!block !text-primary hover:!text-white text-center mt-6' }}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
