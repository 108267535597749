import React from 'react';
import { LinkBox } from '@components/organisms/LinkBox/LinkBox';

export default function El({ externalLinks, headline }) {
  return (
    <div className="md:px-16 xl:px-32">
      <LinkBox externalLinks={externalLinks} title={headline} />
    </div>
  );
}
