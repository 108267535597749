import React, { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';

const Header = dynamic(() => import('@components/organisms/Header/Header').then((module) => module.Header));
const Footer = dynamic(() => import('@components/organisms/Footer/Footer').then((module) => module.Footer));

export const Article = ({ children }: PropsWithChildren) => {
  return (
    <div className="grid min-h-screen grid-rows-[1fr,auto] bg-gradient-to-r from-white via-gray-100 to-white text-gray-600">
      <Header classProps={{ root: '' }} filled={true} />
      <main className="w-full min-w-0 text-clip lg:mt-24">{children}</main>
      <Footer />
    </div>
  );
};
