import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { MenuItem, PropsWithClassProps } from '@vgn-medien-holding/vgn-react-components';

export type NavigationClassProps = PropsWithClassProps<'heading' | 'list' | 'listItem' | 'link'>;

export interface NavigationProps extends NavigationClassProps, PropsWithChildren {
  items: MenuItem[];
  renderLink?: (Link, index: number) => ReactNode;
  title?: string;
  withHeading?: boolean;
}

export const Navigation: FC<NavigationProps> = ({
  classProps,
  items,
  title = 'Navigation',
  withHeading = false,
  renderLink,
  children,
}) => (
  <nav className={classProps?.root} aria-label={title}>
    {withHeading && <div className={classProps?.heading}>{title}</div>}
    {items && items.length > 0 && (
      <ul className={classProps?.list}>
        {items.map((link, index) => {
          const externalLink = !(link.url.includes('tv-media') || link.url.startsWith('/'));

          return (
            <li key={index} className={classProps?.listItem}>
              {renderLink ? (
                renderLink(link || '', index)
              ) : (
                <a
                  href={link.slug || link.url || ''}
                  target={externalLink ? '_blank' : ''}
                  rel={externalLink ? 'noopener noreferrer' : ''}
                  className={classProps?.link}
                >
                  {link.title}
                </a>
              )}
            </li>
          );
        })}
        {children}
      </ul>
    )}
  </nav>
);
